.header {
	.logo {
		width: 160px;
	}
}
.home {
	background-color: #00ac91ff;
	min-height: 500px;
	padding-top: 100px;
	.section-heading {
		color: #fff;
		text-transform: lowercase;
	}
	#rcode {
		text-align: center;
		font-weight: bold;
		color: #007253;
		text-transform: uppercase;
	}
	.align-error {
		color: red;
		margin-top: 5px;
		margin-bottom: 10px;
		font-size: 18px;
	}
	.btn-main:hover {
		background-color: #ac3200ff !important;
		color: white !important;
		border-color: black;
	}
	.btn-main {
		background-color: #00ac91ff !important;
		border-color: #fff;
		padding: 0.7rem 6rem !important;
		font-size: 18px;
	}
}
.footer {
	border-top: #ac3200ff 2px solid;
	h5 {
		color: #00ac91ff;
	}
}
.reward-popup-btn {
	background-color: #00ac91ff !important;
	border-color: #fff;
	text-transform: uppercase;
	padding: 1% 5% 1% 5%;
}
.icon-warning {
	font-size: 5rem;
	color: #f8bb86;
}
.icon-success {
	font-size: 5rem;
	color: green;
}
